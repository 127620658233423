/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import DatePicker from 'react-date-picker';
import { useSportMatches } from '../hooks';
import ScheduleViewer from './ScheduleViewer';

const Schedule = () => {
  const [treeData, setTreeData] = useState(null);
  const [items] = React.useState([
    { label: 'Soccer', value: '1' },
    { label: 'Basketball', value: '2' },
    { label: 'Tennis', value: '5' },
  ]);
  const [sport, setSport] = useState('2');
  const [date, changeDate] = useState(new Date(Date.now()));
  const offset = date.getTimezoneOffset();

  const url = `https://lsstreaming.betradar.com/ls/feeds/?/whitelabel1/en/Europe:Berlin/gismo/sport_matches/${sport}/${new Date(date.getTime() - (offset * 60 * 1000)).toISOString().split('T')[0]}`;

  const {
    isLoading, error, data, refetch,
  } = useQuery(
    'repoData',
    () => fetch(url).then(res => res.json()), {
      refetchOnWindowFocus: false,
      enabled: false, // needed to handle refetchs manually
    },
  );
  const runQuery = useCallback(
    () => {
      refetch();
    },
    [refetch],
  );

  const dd = useSportMatches(data);

  const a = 1231;

  console.log('tes', dd);
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}
    >
      <div style={{
        display: 'flex',
      }}
      >
        <div style={{
          display: 'flex',
          background: 'white',
          width: 'fit-content',
          marginBottom: 20,
        }}
        >
          <DatePicker
            onChange={changeDate}
            locale='no-NO'
            value={date}
          />

        </div>
        <select
          value={sport}
          onChange={e => setSport(e.currentTarget.value)}
          style={{
            display: 'flex',
            marginLeft: 20,
            marginBottom: 20,
          }}
        >
          {items.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>

      <button
        variant='contained'
        style={{
          textTransform: 'none', width: 200, height: 55, fontSize: 26, marginBottom: 20,
        }}
        color='primary'
        onClick={runQuery}
      >
        Run Query
      </button>

      {dd && (
        <ScheduleViewer data={dd} />
      )}

    </div>
  );
};

Schedule.propTypes = {

};

export default Schedule;
