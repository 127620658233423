import React from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import './App.css';
import Schedule from './components/Schedule';
import { useTwoKeyCount } from './hooks';

function App() {
  const keyCount = useTwoKeyCount('ctrl', 'q');
  const queryClient = new QueryClient();

  return (
    <div className='App' key={keyCount}>
      <QueryClientProvider client={queryClient}>
        <div style={{
          display: 'flex',
          height: 60,
          // flex: 1,
          background: '#ef6c00',
          fontFamily: "'Roboto' sans serif",
          fontSize: 40,
          alignItems: 'center',
          paddingLeft: '5%',
        }}
        >
          Moving Lines
        </div>
        <div style={{
          display: 'flex',
          padding: 20,
          flex: 1,
        }}
        >
          <Schedule />

        </div>
      </QueryClientProvider>
    </div>
  );
}

export default App;
