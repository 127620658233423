/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useCallback } from 'react';

function List({ items, level }) {
  const [displayChildren, setDisplayChildren] = useState({});
  const [displayProps, setDisplayProps] = useState({});

  const listitems = Object.values(items).map(item => {
    const a = 123;

    return (
      <li key={item.label}>
        {item.label}{' '}

        {item.nodes && (
          <button
            onClick={() => {
              setDisplayChildren({
                ...displayChildren,
                [item.label]: !displayChildren[item.label],
              });
            }}
          >
            {displayChildren[item.label] ? '-' : '+'}
          </button>
        )}

        {item.isMatch && (
        <button
          onClick={() => {
            setDisplayProps({
              ...displayProps,
              [item.label]: !displayProps[item.label],
            });
          }}
        >
          {displayProps[item.label] ? '-' : '+'}
        </button>
        )}

        {displayChildren[item.label] && item.nodes && <List items={item.nodes} level={level + 1} />}

        {
          displayProps[item.label] && (
            <pre>
              {JSON.stringify(item, null, 2)}
            </pre>
          )
        }
      </li>
    );
  });

  return (
    <ul style={{
      fontSize: 20 + (3 - level) * 4,
    }}
    >
      {listitems}
    </ul>
  );
}

const ScheduleViewer = ({ data }) => {
  const [treeData, setTreeData] = useState(null);

  return (
    <div style={{ padding: 20 }}>
      <List items={data} level={1} />
    </div>
  );
};

ScheduleViewer.propTypes = {

};

export default ScheduleViewer;
