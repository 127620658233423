/* eslint-disable no-param-reassign */
import {
  useState, useCallback, useEffect, useRef,
} from 'react';

export const useSportMatches = response => {
  const [treeData, setTreeData] = useState(null);

  useEffect(() => {
    if (!response) {
      return;
    }
    console.log('r', response.doc[0]);

    const realCats = Object.values(response.doc[0].data.sport.realcategories).reverse();
    const abc = realCats.reduce((realAcc, cur, rI) => {
      const tours = cur.tournaments.reduce((tourAcc, tour, tI) => {
        const matches = Object.values(tour.matches)
          .reduce((matchAcc, match, mI) => {
            const matchup = match.teams.home.mediumname + ' - ' + match.teams.away.mediumname;

            const matchObj = {
              id: match._id,
              label: matchup,
              index: mI,
              isMatch: true,
              date: new Date(match._dt.uts * 1000).toLocaleString(),
              statusName: match.status.name,
              statusId: match.status._id,
              matchup,
              result: match.result,
              resultStr: match.result.home + ' - ' + match.result.away,

            };

            matchAcc[matchup] = matchObj;
            return matchAcc;
          }, {});

        const tourTree = {
          label: tour.name,
          index: tI,
          // t,
          nodes: matches,

        };

        tourAcc[tour.name] = tourTree;
        return tourAcc;
      }, {});

      const realTree = {
        label: cur.name,
        index: rI,
        nodes: tours,

      };

      realAcc[cur.name] = realTree;
      return realAcc;
    }, {});

    setTreeData(abc);
  }, [response]);

  return treeData;
};

export function useKey(key) {
  // Keep track of key state
  const [pressed, setPressed] = useState(false);

  // Bind and unbind events
  useEffect(() => {
    const match = event => {
      if (key === 'ctrl' && event.ctrlKey) {
        return true;
      }
      else if (key && event.key) {
        return key.toLowerCase() === event.key.toLowerCase();
        //
      }
      else {
        return false;
      }
    };

    // Event handlers
    const onDown = event => {
      if (match(event)) setPressed(true);
    };

    const onUp = event => {
      if (match(event)) setPressed(false);
    };

    window.addEventListener('keydown', onDown);
    window.addEventListener('keyup', onUp);
    return () => {
      window.removeEventListener('keydown', onDown);
      window.removeEventListener('keyup', onUp);
    };
  }, [key]);

  return pressed;
}

export const useTwoKeyCount = (k1, k2) => {
  const [bothKeysDown, setBothKeysDown] = useState(true);

  const [count, setCount] = useState(0);
  const key1 = useKey(k1);
  const key2 = useKey(k2);

  useEffect(() => {
    if (key1 && key2) {
      setCount(c => c + 1);
    }
  }, [key1, key2]);

  return count;
};
